import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Environment, EnvService } from '@shared/environment';
import { FullStoryService } from '@fullstory/fullstory.service';
import { GainsightService } from '@gainsight';
import { LANGUAGES } from '@shared/constants';
import { GoParams, IOrgConfig, IUserMessage } from '@shared/interfaces';
import { NotificationComponent } from '@ui/components/notification';
import { NotificationIcons, NotificationTypes } from '@shared/enums';
import * as UserStore from '@user/user.store';
import { userActions } from '@user/user.store';
import * as NotificationMessagesStore from '@notification-messages';
import * as AppSettingsStore from '@app-settings/app-settings.store';
import { orgConfigActions, getOrgConfig } from '@org-config';
import {
  navMenusActions,
  selectSwitcherContent,
  getActiveNavItemWithCategories,
  getNavMenu,
  selectSelectedRouteItem,
  selectSpecificLink,
  getParentLink,
} from '@nav-menus';
import * as HubRouterSelectors from '@hub-router';
import * as HubTokenSelectors from '@hub-token';
import { NavigateTo } from '@hub-navigation';
import { dateCohortsActions } from '@date-cohorts';
import * as SidenavUtilitiesStore from '@shared/features/sidenav-utilities';
import { selectIsUserProfileLoaded, selectUserProfile } from '@shared/data-access/user-common';
import { getIsSplitIoFlagOn, treatmentTokens } from '@shared/splitio';
import { GreenfieldBridge } from '@util/greenfield-bridge';

@Component({
  selector: 'ds-root-container',
  templateUrl: './app-container.component.html',
  styleUrls: ['./app-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppContainerComponent implements OnInit {
  public baseHubPath: string;
  public userProfile$ = this.store.pipe(select(selectUserProfile));
  public switcherContent$ = this.store.pipe(select(selectSwitcherContent));
  public selectedNavItem$ = this.store.pipe(select(getActiveNavItemWithCategories));
  public navMenu$ = this.store.pipe(select(getNavMenu));
  public zdSsoAccess$ = this.store.pipe(select(
    getIsSplitIoFlagOn(treatmentTokens.zd_sso)
  ));
  public sidenavUserMenuLinks$ = this.store.pipe(
    select(UserStore.getSidenavUserMenu(this.envService.getEnv().UNIFIED_LOGIN_URL))
  );
  public selectedRoute$ = this.store.pipe(select(selectSelectedRouteItem));
  public isNewLayout$ = this.store.pipe(select(HubRouterSelectors.isNewLayout));
  public specificLink$ = this.store.pipe(select(selectSpecificLink));
  public appSettings$ = this.store.pipe(select(AppSettingsStore.selectAppSettingsState));
  public isUtilitiesSidenavVisible$ = this.store.pipe(select(SidenavUtilitiesStore.isRouteWithUtilitiesSidenav));
  public rightSidenavExtended$ = this.store.pipe(select(SidenavUtilitiesStore.getIsSidenavExtended));
  public hubToken$ = this.store.pipe(select(HubTokenSelectors.selectHubToken));
  public parent$ = this.store.pipe(select(getParentLink));

  private envs: Environment;

  constructor(
    private readonly fullStoryService: FullStoryService,
    private readonly gainsightService: GainsightService,
    private readonly store: Store<unknown>,
    private readonly translateService: TranslateService,
    private readonly snackBar: MatSnackBar,
    private readonly envService: EnvService,
    public readonly greenfieldBridge: GreenfieldBridge
  ) {
    this.envs = this.envService.getEnv();
    this.baseHubPath = this.envs.HUB_AJS_URL;
  }

  ngOnInit(): void {
    this.initFullStory();
    this.initGainsight();
    this.initLanguages();
    this.getInitialData();
    this.showNotifications();
  }

  switchUser(username: string): void {
    this.store.dispatch(userActions.switchUser({ user: username }));
  }

  initFullStory(): void {
    if (this.envs.FULL_STORY_ENABLED) {
      this.fullStoryService.injectSnippet();
    }
  }

  initGainsight(): void {
    if (this.envs.production && this.envs.GAINSIGHT_KEY) {
      this.gainsightService.injectSnippet(this.envs.GAINSIGHT_KEY);
    }
  }

  initLanguages(): void {
    this.translateService.addLangs(LANGUAGES);
    this.translateService.use(LANGUAGES[0]);
  }

  navigate(route: GoParams): void {
    this.store.dispatch(NavigateTo(route));
  }

  getInitialData(): void {
    this.store.pipe(select(selectIsUserProfileLoaded)).pipe(
      filter((isLoaded: boolean) => isLoaded),
      take(1)
    ).subscribe(() => {
      this.store.dispatch(AppSettingsStore.appSettingsActions.loadDcLastUpdatedDate());
      this.store.dispatch(userActions.loadCustomerEntitlementAuthorize());
      this.store.dispatch(orgConfigActions.LoadOrgConfig());
      this.store.dispatch(orgConfigActions.LoadCrmUrl());
      this.store.dispatch(dateCohortsActions.loadDateCohorts());
    });

    combineLatest([
      this.store.pipe(select(getOrgConfig)),
      this.store.pipe(select(selectIsUserProfileLoaded)),
    ]).pipe(
      filter(([config, isLoaded]: [IOrgConfig, boolean]) => !!config && isLoaded),
      take(1),
    ).subscribe(() => {
      this.store.dispatch(navMenusActions.Load());
    });
  }

  showNotifications(): void {
    this.store.pipe(select(NotificationMessagesStore.getLastMessage))
      .pipe(
        filter((message: IUserMessage) => !!message),
      )
      .subscribe((message: IUserMessage) => {
        this.snackBar.openFromComponent(NotificationComponent, {
          data: {
            message: message.message,
            type: message.type,
            icon: this.getNotificationIcon(message.type),
          }
        });
      });
  }

  private getNotificationIcon(messageType: NotificationTypes): NotificationIcons {
    switch (messageType) {
      case NotificationTypes.Error:
        return NotificationIcons.Exclamation;

      case NotificationTypes.Success:
        return NotificationIcons.Check;

      default:
        return;
    }
  }
}
